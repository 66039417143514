#drawer-bottom-membership{
  .MuiPaper-root {
    max-width: 100% !important;
    width: 100%;
    z-index: 111111;
  }

  // Drawer Height incres
  .MuiBox-root{
    top: -330px;

    .MuiBox-root{
      background-color: transparent;
    }
  }
}

.PrivateSwipeArea-root{
  height: 0 !important;
}

.product-cart-wrap {
  .react-responsive-modal-modal {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.react-multi-carousel-track {
  gap: 18px;
}

.spinner {
  border: 11px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border-top: 11px solid #fff;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product-cart-wrap {
  cursor: default;
  // min-height: 308px;

  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;

  .product-img-action-wrap {
    padding: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    overflow: visible;

    .vegicon-img {
      position: absolute;
      z-index: 1111;
      top: 10px;
      left: -8px;
      width: 15px;
    }
  }

  .card-badge {
    position: absolute;
    z-index: 11;
    top: 0px;
    // right: 0px;
  }

  .react-multi-carousel-list {
    .react-multi-carousel-item {
      width: 170px !important;
    }

    .react-multi-carousel-track {
      gap: 18px;
    }
  }

  .product-content-wrap {
    box-sizing: border-box;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    width: 100%;

    .product-category {
      a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 9px;
        color: rgb(54, 54, 54);
      }
    }

    h2 {
      overflow: hidden;
      color: rgb(31, 31, 31);
      font-weight: 600;
      font-size: 13px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;
      width: 100%;
      height: 36px;
      margin-bottom: 6px;
    }

    .form-select {
      color: rgb(31, 31, 31);
      font-weight: 500;
      font-size: 9px;
      cursor: pointer;
    }

    .product-price {
      span {
        color: rgb(31, 31, 31);
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }

      .old-price {
        font-weight: 400;
        font-size: 12px !important;
      }
    }

    .add-btn {
      .button {
        font-family: "Okra", Helvetica;
        cursor: pointer;
        // width: 66px;
        width: auto;
        max-width: 80px;
        border: 1px solid rgb(49, 134, 22);
        height: 31px;
        color: rgb(49, 134, 22);
        font-weight: 600;
        font-size: 13px;

        background-color: rgb(247, 255, 249);
        border-radius: 0.375rem;
        gap: 0.125rem;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        text-transform: uppercase;
      }

      .vegicon-img {
        position: absolute;
        z-index: 1111;
        top: 10px;
        left: -8px;
        width: 15px;
      }
    }

    .card-badge {
      position: absolute;
      z-index: 11;
      top: 0px;
      // right: 0px;
    }

    .react-multi-carousel-list {
      .react-multi-carousel-item {
        width: 170px !important;
      }

      .react-multi-carousel-track {
        gap: 18px;
      }
    }

    .product-content-wrap {
      box-sizing: border-box;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-bottom: 0.75rem;
      width: 100%;

      .product-category {
        a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 9px;
          color: rgb(54, 54, 54);
        }
      }

      h2 {
        overflow: hidden;
        color: rgb(31, 31, 31);
        font-weight: 600;
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
        width: 100%;
        height: 36px;
        margin-bottom: 6px;
      }

      .form-select {
        color: rgb(31, 31, 31);
        font-weight: 500;
        font-size: 9px;
        cursor: pointer;
      }

      .product-price {
        span {
          color: rgb(31, 31, 31);
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }

        .old-price {
          font-weight: 400;
          font-size: 11px !important;
        }
      }

      .add-btn {
        .button {
          font-family: "Okra", Helvetica;
          cursor: pointer;
          // width: 66px;
          width: auto;
          max-width: 87px;
          border: 1px solid rgb(49, 134, 22);
          height: 35px;
          color: rgb(49, 134, 22);
          font-weight: 600;
          font-size: 13px;
          text-align: center;

          background-color: rgb(247, 255, 249);
          border-radius: 0.375rem;
          gap: 0.125rem;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          text-transform: uppercase;
        }

        .button.get-membership {
          font-size: 9px !important;
        }

        .button.active {
          background-color: rgb(49, 134, 22);
          color: #fff;
        }
      }
    }

    .product-card-bottom {
      margin-top: 0;
    }
  }

  .product-card-bottom {
    margin-top: 0;
  }
}

.modal-product-card.product-cart-wrap {
  .product-img-zoom a {
    img {
      width: 100%;
    }
  }
}

.button.change-plan-btn {
  font-size: 10px !important;
  line-height: 12px !important;
  text-align: center;
  padding: 10px 5px !important;
  text-transform: capitalize !important;
}

@media screen and (max-width: 900px) {
  .product-cart-wrap {
    .product-img-action-wrap {
      .vegicon-img {
        left: -2px;
      }
    }
  }
  .react-multi-carousel-track {
    gap: 8px;
  }
}
.veg-icon {
  position: absolute;
  z-index: 1;
  width: 15px;
  left: 12px;
  top: 11px;
}

.btn-sm {
  padding: 0.5rem 0px !important;
  font-size: 13px;
}

.modal-product-card.product-cart-wrap {
  .veg-icon {
    top: 40px;
  }
}

@media screen and (min-width: 375px) and (max-width: 667px) {
  .product-cart-wrap {
    .product-content-wrap {
      h2 {
        font-size: 12px !important;
      }
      .product-price span {
        font-size: 13px !important;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .modal-product-card.product-cart-wrap:not(:last-child) {
    margin-bottom: 5px !important;
  }

  .shop-product-fillter .sort-by-product-area .sort-by-cover {
    width: 100%;
  }

  .product-cart-wrap.card-style:not(:last-child),
  .mb-xs-30 {
    margin-bottom: 0px !important;
  }
}
