.app-download {
    display: flex;
    justify-content: space-around;
    align-items: center;
    direction: rtl;
    // background-color: #dc1e4d;
    // background-color: #263e98;
    background-color: #4CAF50;
    top: 0;
    position: absolute;
    flex-direction: column;
    z-index: 100;
    right: 1rem;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    // box-shadow: 1px 2px 14px 5px #00000070;
    box-shadow: 1px 4px 14px 0px #00000082;
    padding: 5px;
    &:focus {
      outline: none;
    }
    label{
      // margin-top: .5rem;
      margin-bottom: 0;
      color: #fff;
      cursor: pointer;
      &#first-title {
        font-size: 1rem;
      }  
      &#second-title{
        font-size: 12px;
        font-weight: 700;
      }
    }
    
    .fa-mobile-phone {
      color: #fff;
      font-size: 2.5rem;
      width: 45px;
      height: 45px;
      display: flex;
      border: 2px dotted #fff;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: .5rem 0;
      cursor: pointer;
    }
    .close-download-app {
      color: #fff;
      margin-top: .5rem;
      font-size: .7rem;
      cursor: pointer;
    }
  }

.bounce {
animation: bounce 0.5s;
animation-direction: alternate;
animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
animation-iteration-count: infinite;
-webkit-animation-name: bounce;
-webkit-animation-duration: 0.5s;
-webkit-animation-direction: alternate;
-webkit-animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
-webkit-animation-iteration-count: infinite;
}

@keyframes bounce {
from {
  transform: translate3d(0, 0, 0);
}
to {
  transform: translate3d(0, 3px, 0);
}
}

@-webkit-keyframes bounce {
from {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
to {
  -webkit-transform: translate3d(0, 3px, 0);
  transform: translate3d(0, 3px, 0);
}
}


// =====================


.ribbon2 {
  width: 40px;
  padding: 10px 0 0;
  position: absolute;
  top: -8px;
    left: 38px;
  text-align: center;
  border-top-left-radius: 3px;
  background: #4CAF50;
  display: flex;
    flex-direction: column;
    // box-shadow: 1px 4px 14px 0px #00000082;

 }
 .ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid #8D5A20;
  border-right: 6px solid transparent;
 }
 .ribbon2:before, .ribbon2:after {
   content: "";
   position: absolute;
 }
 .ribbon2:after {
   height: 0;
   width: 0;
   bottom: -19.5px;
   left: 0;
   border-left: 20px solid #4CAF50;
   border-right: 20px solid #4CAF50;
   border-bottom: 20px solid transparent;
 }


 .ribbon {
  // height: 188px;
  position: relative;
  margin-bottom: 30px;
  // background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
  background-size: cover;
  text-transform: uppercase;
  color: white;
  z-index: 1;

}

@media screen and (max-width: 500px) {
  .ribbon2 {
    left: 20px;
  }
}
@media screen and (max-width: 820px) {
  .ribbon2 {
    left: 25px;
  }
}