.view-cart-btn {
  align-items: center;
  max-width: 400px;
  width: 85%;
  position: fixed;
  left: 12px;
  right: 12px;
  display: flex;
  z-index: 10001;
  background: rgb(12, 131, 31);
  bottom: 12px;
  color: white;
  border-radius: 12px;
  padding: 6px 18px 6px 12px;
  margin: 0px auto;
  -webkit-box-pack: justify;
  justify-content: space-between;
  transition: transform 300ms linear 0s;
  transform: translate(0px);
  opacity: 1;
  z-index: 11111;

  .cart-icon {
    background: #ffffff3d;
    width: 35px;
    height: 35px;
    padding: 7px;
    border-radius: 10px;
  }

  .shoppig {
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 2px;
    }

    .amount {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      margin-right: 4px;
    }

    .view {
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

.cart-dropdown-wrap ul {
  margin-bottom: 0;
  li {
    margin: 0 0 15px !important;
    .link {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 12px;
      svg {
        width: 22px;
        fill: #00000094;
      }
    }
  }
}
.cart-dropdown-wrap ul li:hover {
  .link {
    color: rgb(12, 131, 31);
  }
  svg {
    fill: rgb(12, 131, 31);
  }
}

.cart-dropdown-wrap ul li:last-child {
  margin-bottom: 0 !important;
}

@media (min-width: 1260px) {
  .header-style-1 {
    .container-fluid {
      max-width: 100%;
    }
  }
}

@media (max-width: 1020px) {
  .header-bottom {
    .header-wrap .header-right {
      width: 100%;
      margin-bottom: 2px;
    }

    .search-style-3 div input {
      padding: 3px 30px 3px 37px;
      width: 100%;
    }
  }
  .user-icon {
    svg {
      width: 32px;
      height: 32px;
      color: #000;
    }
  }

  .mobile-view {
    .title-head {
      color: rgb(0, 0, 0);
      font-weight: 800;
      margin-bottom: 6px;
      font-size: 16px;
    }
    .address-text {
      font-size: 13px;
      color: rgb(0, 0, 0);
      font-weight: 400;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      width: auto;
      max-width: 210px;
      text-overflow: ellipsis;
    }
  }
}


.myClass {

  .customavi-pac-container{
    left: 83px!important;
    top: 210px !important;
    width: 460px !important;
  }

  .pac-container1 {
    border: unset !important;
    border-radius: 4px !important;
    box-shadow: unset !important;
    font-size: 14px !important;
    z-index: 11111111 !important;
    width: 560px !important;
    left: 5.2rem !important;
    top: 16rem !important;
  }


  @media only screen and (max-width: 600px) {

    .customavi-pac-container{
      // width: 150px;
      left: 0px !important;
      top: 429px !important;
      width: 355px !important;
      }


    .pac-container1 {
      // position: inherit !important;
      width: 92%;
      left: 1.2rem !important;
      width: 90% !important;
      top:21rem !important;
    }
  }
  .pac-item {
    font-size: 15px !important;
    padding: 16px !important ;
  }

  .pac-item-query {
    font-size: 15px !important;
  }
}

.cat-itm.active {
  color: rgb(12, 131, 31) !important;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .header-style-1 .header-bottom {
    display: block !important;
  }
  .mobile-view {
    .title-head {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 412px) {
  .mobile-view {
    .title-head {
      font-size: 12px;
    }
    .address-text {
      font-size: 12px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header-wrap .header-right {
    width: 60% !important;
  }
}


@media only screen and (min-width: 1021px) {
  .header-wrap .header-right {
    width: 60% !important;
    gap: 1rem !important;
    margin-left: auto;
  }
}

@media only screen and (min-width: 1021px) and (max-width: 1200px) {
  // .header-wrap .header-right {
  //   width: 55% !important;
  //   gap: 1rem !important;
  //   margin-left: auto;
  // }
  .header-action-right {
    display: flex !important;
    justify-content: flex-end;
    flex-shrink: 0;
    align-items: center;
  }
  .location h6 {
    font-size: 12px;
  }
  .header-wrap.header-space-between {
    justify-content: flex-start !important;
    gap: 12px;
  }
}
