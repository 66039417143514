.breadcrumb {
  border-bottom: 0.5px solid rgb(232, 232, 232);
  padding-bottom: 8px !important;
  background-color: #ffffff;
  .link {
    font-size: 13px;
    color: #1f1f1f;
    svg {
      width: 20px;
      fill: #94a3b8;
    }
  }
  .link:hover {
    color: #0c831f;
  }
  .link svg:hover {
    fill: #0c831f;
  }
  .active {
    font-weight: 600;
    cursor: default;
  }
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    padding: 0 5px 5px !important;
  }
}

#category-menu-drawer {
  .MuiPaper-root {
    width: 60%;
    max-width: 100% !important;
    height: 100vh !important;
  }
}

@media (max-width: 1020px) {
  .breadcrumb {
    .mobile-view {
      display: contents !important;
      width: 100%;
    }
  }
}
