:root { 
--gray_52:#f9fdff; 
--deep_orange_50:#fbeee6; 
--gray_51:#fff9f6; 
--gray_900_cc:#191919cc; 
--red_101:#ecd6c8; 
--gray_50:#fff9f5; 
--red_100:#ffe0ce; 
--deep_orange_900:#883301; 
--deep_orange_300:#ff9e65; 
--deep_orange_400:#ff8b46; 
--gray_600:#6e6e6e; 
--gray_700:#626262; 
--bluegray_300_33:#91aebb33; 
--gray_400:#c4c4c4; 
--gray_401:#c0c0c0; 
--gray_604:#787878; 
--orange_A700:#fd650b; 
--gray_900:#191919; 
--bluegray_100:#d6d6d6; 
--yellow_50:#fff7f0; 
--gray_300:#dbdbdb; 
--bluegray_600:#406f85; 
--bluegray_102:#cfcfcf; 
--bluegray_101:#d9d9d9; 
--white_A700:#ffffff; 
}