// .view-cart-btn {
//   align-items: center;
//   max-width: 400px;
//   width: 85%;
//   position: fixed;
//   left: 12px;
//   right: 12px;
//   display: flex;
//   z-index: 10001;
//   background: rgb(12, 131, 31);
//   bottom: 12px;
//   color: white;
//   border-radius: 12px;
//   padding: 6px 18px 6px 12px;
//   margin: 0px auto;
//   -webkit-box-pack: justify;
//   justify-content: space-between;
//   transition: transform 300ms linear 0s;
//   transform: translate(0px);
//   opacity: 1;
//   z-index: 11;

//   .cart-icon {
//     background: #ffffff3d;
//     width: 35px;
//     height: 35px;
//     padding: 7px;
//     border-radius: 10px;
//   }

//   .shoppig {

//     span {
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 14px;
//       margin-bottom: 2px;
//     }

//     .amount {
//       font-weight: 500;
//       font-size: 15px;
//       line-height: 20px;
//       margin-right: 4px;
//     }

//     .view {
//       span {
//         font-style: normal;
//         font-weight: 400;
//         font-size: 17px;
//         line-height: 22px;
//       }
//     }
//   }
// }

#mycart-drawer {
  
  .MuiAccordionDetails-root {
    padding: 8px 0px 10px;
  }
  .MuiPaper-root {
    // max-width: 400px !important;
    // max-width: 100% !important;
    // width: 100%;
    height: 100vh !important;
    width: 400px;
  }
  .MuiPaper-root.MuiAccordion-root::before {
    background-color: transparent;
  }

  .MuiPaper-root.MuiAccordion-root {
    height: auto !important;
    margin: 16px 0;
    box-shadow: none;

    .MuiButtonBase-root {
      padding: 0;
      min-height: 50px;
    }
    .MuiAccordionSummary-content {
      margin: 0 0;
    }
  }
}

.my-cart-content-parent {
  .MuiPaper-root {
    // max-width: 400px !important;
    max-width: 100% !important;
  }

  .modal-header {
    padding: 18px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  .modal-body {
    overflow-y: scroll;
    height: 100vh;
    padding: 12px;
    padding-bottom: 200px;
  }

  .modal-footer {
    background-color: rgb(255, 255, 255);
    padding: 12px 16px 24px;
    box-sizing: border-box;

    .add-cart {
      display: flex;
      cursor: pointer;
      padding: 5px 12px;
      background-color: rgb(12, 131, 31);
      -webkit-box-align: center;
      align-items: center;
      border-radius: 8px;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
    }
  }
}
.address-cart {
  font-family: "Okra", Helvetica;
  cursor: pointer;
  width: auto;

  border: 1px solid #318616;

  color: #318616;
  font-weight: 600;
  font-size: 13px;
  background-color: #f7fff9;
  border-radius: 10px;
  gap: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
// .address-cart p {
//   text-align: right !important;
// }
.cart-saving-title {
  font-size: 11px;
  font-weight: bold;
  color: #0c831f;
}
.cart-saving-sub-title {
  font-size: 10px;

  color: #0c831f;
}
.cart-saving-root {
  padding: 7px;
  border: 1px solid #0c831f;
  border-radius: 11px;
  background-color: rgb(247, 255, 249);
  margin-top: 0.3rem;
}

// Exciting Deals
.exciting-deals {
  .product-cart-wrap {
    justify-content: flex-start;
    .add-btn .button {
      padding: 5px 5px !important;
      font-size: 11px !important;
    }
  }
  
}

// Coupon Card
.coupon-card {
  .button {
    color: #6466f1;
    box-shadow: 0 0 3px 0;
    padding: 4px 12px !important;
  }
}

@media screen and (max-width: 1024px) {
  .my-cart-content-parent {
    .modal-body {
      // height: 110vh;
      height: auto;
      padding-bottom: 60px;

      .MuiAccordionDetails-root{
        padding: 8px 0px 16px;
      }
      .MuiButtonBase-root.MuiAccordionSummary-root{
        padding: 0 0;
      }
    }
  }
}
